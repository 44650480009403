import React from 'react'
import { Seo } from '../../../components'
import { FaqContent } from '../../../sections'
import { injectIntl, useIntl } from 'gatsby-plugin-intl'
import { FullScreen } from '../../../components/fullScreen'
import { colors } from '../../../utils/const'
import { graphql } from 'gatsby'

function Faq({ data }) {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }

    return (
        <FullScreen theme={colors.darkTheme}>
            <Seo
                title={t('faq_seo_description')}
                description={t('faq_seo_description')}
            />
            <FaqContent mdFiles={data.allMarkdownRemark.edges} />
        </FullScreen>
    )
}

export const query = graphql`
    query {
        allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "*/faq.md$/" } }
        ) {
            edges {
                node {
                    html
                    frontmatter {
                        title
                    }
                }
            }
        }
    }
`

export default injectIntl(Faq)
